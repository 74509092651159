<template>
    <div class="content">
        <div class="search-attr">
            <div>
                <a-input allowClear v-model="params.name" size='small' style="width:150px;" placeholder='标题'></a-input>
                <a-select allowClear  v-model="params.positionType" showSearch size='small' style="width:100px;margin:5px;" placeholder='分类类型'>
                    <a-select-option value="new_index">拍品分类</a-select-option>
                    <a-select-option value="version_picture">图库分类</a-select-option>
                </a-select>

                <a-button @click="search" size='small' style="margin-left:10px;">搜索</a-button>
            </div>
            <a-button size='small' @click="openModal({})">新增分类</a-button>
        </div>
        <div class="table-content" >
            <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>n">
                <div slot="Image" slot-scope="row" style="width:100px;height:100px;display:flex;justify-content:center;align-items:center;">
                    <img style="max-width:100px;max-height:100px;" :src="row">
                </div>
                <div slot="setup" slot-scope="row">
                    <a class="map-margin" @click="openModal(row)">编辑</a>

                    <a-popconfirm
                        title="Are you sure delete this item?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="remove(row)"
                    >
                    <a style='margin-left:5px'>删除</a>
                    </a-popconfirm>
                </div>
            </a-table>
        </div>

        <a-modal centered :width='1000' v-model="showModal" @ok='checkForms' @cancel='cancel'>
            <div style="height:400px;overflow-y:auto;">
                <a-form-model v-bind="layout" :model='forms' :rules='rules' ref="ruleForm">
                    <a-form-model-item label='分类类型' prop='positionType'>
                        <a-select @change="changeSelect"  ref='addse' v-model="forms.positionType" :filter-option="untils.filterOption" showSearch placeholder='请选择'>
                          <a-select-option value="new_index">拍品分类</a-select-option>
                          <a-select-option value="version_picture">图库分类</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    
                    <!-- <a-form-model-item label='图标' prop='icon'>
                        <a-input v-model="forms.icon" allowClear @paste.stop.prevent="untils.pasteImg($event,2,parsetCallback)">
                            <a-upload
                                slot='addonBefore'
                                name="file"
                                :showUploadList='false'
                                :action="ip+'/file/upload'"
                                @change="handleChange($event,forms)">
                                <a> <a-icon type="upload"/></a>
                            </a-upload>
                        </a-input>
                    </a-form-model-item> -->
                     
                    <a-form-model-item label='刷选类型' prop='customType'>
                       <a-select default-value="search"  v-model="forms.customType"  showSearch placeholder='请选择' >
                            <a-select-option value="1">平台大分类</a-select-option>
                            <a-select-option value="2">币种组合</a-select-option>
                            <a-select-option value="4">朝代组合</a-select-option>
                        </a-select>
                    </a-form-model-item>


                    <a-form-model-item label='类别名称' prop='customName'>
                        <a-input placeholder='请输入类别名称' v-model="forms.customName"></a-input>
                    </a-form-model-item> 

                    
                    <a-form-model-item label='类型值' prop='customValue'>
                        <a-textarea placeholder='请输入对应类型值' v-model="forms.customValue"/>
                    </a-form-model-item> 

                    <a-form-model-item label='排序'>
                        <a-input placeholder='越小越靠前' v-model="forms.customSort"></a-input>
                    </a-form-model-item> 
                    
                    
                </a-form-model>
            </div>
        </a-modal>
        
    </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import quillConfig from '../../../untils/qull.config'
import {mapState} from 'vuex'
import moment from 'moment'
export default {
    data(){
        return {
            columns:[
                // {title:'图标',dataIndex:'customImage',scopedSlots: { customRender: 'Image' },},
                {title:'分类名称',dataIndex:'customName'},

                {title:'分类类型',dataIndex:'positionType', align: 'center',
                  customRender:(text)=>{
                    const inner = text
                    if(inner == 'new_index'){
                      return <span style='color:orange'>拍品分类</span>
                    }
                    if(inner == 'version_picture'){
                      return <span style='color:green'>图库分类</span>
                    }
                  }
                },

                {title:'刷选类型',align:'center',dataIndex:'customType',
                    customRender:(text)=>{
                        const inner = text
                        if(inner == '1'){
                            return <span style='color:black'>平台大分类</span>
                        }
                        if(inner == '2'){
                            return <span style='color:red'>币种组合</span>
                        }
                        if(inner == '4'){
                            return <span style='color:blue'>朝代组合</span>
                        }
                    }
                },
                // {title:'值',dataIndex:'value'},
                {title:'排序',align:'center',dataIndex:'customSort'},
                {title:'创建时间',align:'center',dataIndex:'createTime'},
                {title: "操作",align: "center",scopedSlots: { customRender: "setup" },},
            ],
            data:[],
            params:{pageNum:1,pageSize:10,name:''},
            pagination:{
                total:0,
                current:1,
                showTotal:(total)=>`共${total}条`,
            },
            loading:false,
            IO:0,// 0 写入 1新增,
            forms:{
                icon:'',
                type:'',
            },
            rules:{
                positionType:[{ required: true, message: '分类位置', trigger: 'change' }],
                // icon:[{ required: true, message: '图标', trigger: 'blur' }],
                customName:[{ required: true, message: '类别名称', trigger: 'blur' }],
                customType:[{required: true, message: '刷选类型', trigger: 'change' }],
                customValue:[{ required: true, message: '类型值', trigger: 'blur' }],
            },
            showModal:false,
            layout:{
                labelCol: { span:2},//标签占2份  共24份
                wrapperCol: { span:22},
            },
            layoutAsnswe:{
                labelCol: { span:2},//标签占2份  共24份
                wrapperCol: { span:22},
            },
            options:quillConfig,
            visible:false,
            
        
        }
    },
    components:{
    },

    computed:{
        ...mapState(['ip']),
    },
   
    created(){
        this.getData(this.params)
    },
    methods:{
        moment,
        async getData(params){
            this.loading = true
            const res = await this.axios('/dq_admin/customCategory/listByPositionType',{params:params})
            this.loading = false
            const {records,total} = res.data
            this.data = records
            this.pagination.total = total
        },
        search(){
            this.params.pageNum = 1
            this.getData(this.params)
        },
        
        changePage(page){
            const {current,pageSize} = page
            this.pagination.current = current
            this.params.pageNum = current
            this.params.pageSize = pageSize
            this.getData(this.params)
        },
        
        openModal(row){
            this.showModal = true
            const {id = null} = row
            this.IO = id ? 1 : 0
            if(this.IO == 1){//updata
                this.forms = JSON.parse(JSON.stringify(row))
                this.forms.customType = this.forms.customType + "";
            }else{//add
                Object.assign(this.forms,{type:'search'})
            }
        },
        
       
        handleChange(res,forms){
            const {response} = res.file
            const {data} = response || {data:null}
            if(data){
                forms.icon = data
            }
        },
        
        async submit(){
            const res = await this.axios.post('/dq_admin/customCategory/addOrEdit',this.forms)
            if(res.status == 200){
                const {message} = res
                this.$message.success(message)
                this.getData(this.params)
                this.showModal = false
                this.cancel()
            }
        },

        cancel(){
            this.forms = {icon:''}
            this.$refs.ruleForm.resetFields();
        },
        checkForms(){
            this.$refs.ruleForm.validate(valid => {
                if(valid){
                    this.submit(this.forms)
                }else{
                    return false;
                }
            });
        },
        parsetCallback(type,result){
            this.forms.icon = result
        },
        
        remove(row) {
            const id = row.id
            this.axios("/dq_admin/customCategory/delById", {
            params: { id: id },
        }).then((res) => {
            this.getData(this.params);

            this.$message.success(res.message);
            });
         },
    
        
        changeSelect(e){
            this.forms.postionType = e
        },
        
        changeType(e){
            this.forms.type = e
            // Object.assign(this.forms,{type:e})
        }
    }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;padding-bottom:20px;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
.take-box{width:100%;max-height:700px;}
.answer-list{padding: 0 10px;
    .answer-item{display: flex;justify-content: space-between;align-items: flex-end;}
}
</style>